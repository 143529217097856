import React, {Component} from 'react';
import mobx from "mobx"
import {Link} from "react-router-dom"
import appStore from "store/appStore"
import {Button, Container, Icon, Segment, Table} from "semantic-ui-react"

export default class CustomerPage extends Component {

	state = {
		customers: []
	}

	async componentDidMount() {
		this.setState({loading: true})
		let data = await appStore.fetch("/api/customers")
		this.setState({
						  loading: false,
						  customers: data.customers
					  })
	}

	render() {
		const {
				  customers,
				  loading
			  } = this.state
		console.log({customers})
		if (loading) return <div className={"pa3 center mw4"}><Button fluid loading/></div>
		return (
			<div className={"CustomerPage  pt6-ns pt4 pb6"}>
				<Container>
					<h2>Konditionsvereinbarungen</h2>
					<div>Wählen Sie einen Kunden.</div>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell> KdnId</Table.HeaderCell>
								<Table.HeaderCell> Kunde</Table.HeaderCell>
								<Table.HeaderCell> Adresse</Table.HeaderCell>
								<Table.HeaderCell> Vertreter</Table.HeaderCell>
								<Table.HeaderCell> Vertreter (Code) </Table.HeaderCell>
								<Table.HeaderCell> &nbsp; </Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{customers.map(customer => {
								const kundenId = customer["Kdn."] || customer["KdnId"]
								return <Table.Row key={kundenId}>
									<Table.Cell> {kundenId} </Table.Cell>
									<Table.Cell>
										<div className={"f6 black-60"}> {customer["E-Mail_Ansprechpartner"]}</div>
										<div> {customer.Name} {customer.Name_2} </div>
									</Table.Cell>
									<Table.Cell>
										<div className={"f6 black-60"}> {customer["Straße"]}</div>
										<div> {customer.PLZ} {customer.Ort}</div>
									</Table.Cell>
									<Table.Cell> {customer["E-Mail_Vertreter"]} </Table.Cell>
									<Table.Cell> {customer["Vertreter-Code"]} </Table.Cell>
									<Table.Cell> <Button as={Link} to={`/customer/${kundenId}`}>Auswählen</Button></Table.Cell>
								</Table.Row>
							})}
						</Table.Body>
					</Table>

					<Button as={Link} to={`/customer/new`} floated={"right"}> <Icon name={"plus"}/> Neuer Kunde</Button>
				</Container>

				<div className="fixed bottom-0 left-0 right-0">
					<Segment clearing>
						<Button onClick={() => {
							appStore.logout()
						}} basic as={Link} to={"/"}><Icon name={"chevron left"}/> Abmelden</Button>
					</Segment>
				</div>

			</div>
		);
	}
}
