import React from "react"
import SignaturePad from "signature_pad"

export default class Signature extends React.Component {

  constructor( props ) {
    super( props );
    this.canvasRef = React.createRef();
    this.containerRef = React.createRef();
  }

  componentWillUnmount() {
    this.signaturePad.off()
  }

  componentDidMount() {
    let canvas = this.canvasRef.current
    let container = this.containerRef.current

    window.container = container

    canvas.width = container.clientWidth

    let signaturePad = new SignaturePad( canvas );
    this.signaturePad = signaturePad

    let { name, store } = this.props
    store[ "signatures" ][ name ] = signaturePad
//     var signaturePad = new SignaturePad(canvas);
//
// // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
//     signaturePad.toDataURL(); // save image as PNG
//     signaturePad.toDataURL("image/jpeg"); // save image as JPEG
//     signaturePad.toDataURL("image/svg+xml"); // save image as SVG
//
// // Draws signature image from data URL.
// // NOTE: This method does not populate internal data structure that represents drawn signature. Thus, after using #fromDataURL, #toData won't work properly.
//     signaturePad.fromDataURL("data:image/png;base64,iVBORw0K...");
//
// // Returns signature image as an array of point groups
//     const data = signaturePad.toData();
//
// // Draws signature image from an array of point groups
//     signaturePad.fromData(data);
//
// // Clears the canvas
//     signaturePad.clear();
//
// // Returns true if canvas is empty, otherwise returns false
//     signaturePad.isEmpty();
//
// // Unbinds all event handlers
//     signaturePad.off();
//
// // Rebinds all event handlers
//     signaturePad.on();
  }


  render() {
    const { className = "" } = this.props
    return <div ref={this.containerRef} className={`SignaturePad ${className}`}>
      <canvas ref={this.canvasRef}/>
    </div>
  }
}