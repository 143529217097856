import ContractDocument from "component/ContractDocument"
import React, {Component} from 'react';
import {Link} from "react-router-dom"

import {Accordion, Button, Container, Icon, Segment} from "semantic-ui-react"
import appStore from "../store/appStore"

export default class SuccessPage extends Component {
  state = { activeIndex: false }

  handleClick = ( e, titleProps ) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState( { activeIndex: newIndex } )
  }
  render() {
    const result = this.props
    const key = this.props.params.hash
    const { form, signatures, info } = appStore.successByKey[key] || {}
    return (
      <div className={"SuccessPage"}>

        <Container>
          <Segment>
            <div className="tc">Konditionsvereinbarung angelegt</div>

            <ContractDocument form={form} signatures={signatures} info={info}/>

          </Segment>
        </Container>


        <div className="pt4">

          <Container>
            <Segment inverted>
              <Accordion inverted>
                <Accordion.Title
                  active={this.state.activeIndex === 0}
                  index={0}
                  onClick={this.handleClick}
                >
                  <Icon name='dropdown'/>
                  Übertragungsprotokoll
                </Accordion.Title>
                <Accordion.Content active={this.state.activeIndex === 0}>
                  <pre>{JSON.stringify( result, null, 2 )}</pre>
                </Accordion.Content>
              </Accordion>
            </Segment>
          </Container>

        </div>


        <Segment>
          <Button basic as={Link} to={"/customer"}><Icon name={"chevron left"}/> Zurück zur Kundenauswahl</Button>
        </Segment>

      </div>
    );
  }
}
