import React, {Component} from 'react';
import 'App.css';
import "tachyons.css"
import "semantic-ui-css/semantic.css"

import LoginPage from "page/LoginPage"
import CustomerPage from "page/CustomerPage"
import ContractPage from "page/ContractPage"
import PDFPage from "page/PDFPage"
import SuccessPage from "page/SuccessPage"
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router, Route} from "react-router-dom";

import ScrollToTop from "component/ScrollToTop"
import {Routes, useLocation, useParams} from "react-router"
const {useNavigate} = require("react-router");

window.toast = toast

const wrapHistory = Component => props => {
    const history = useNavigate()
    const location = useLocation()
    const params = useParams()
    return <Component history={history} location={location} params={params} {...props} />
}


const RoutedContractPage = wrapHistory(ContractPage)
const RoutedCustomerPage = wrapHistory(CustomerPage)
const RoutedSuccessPage = wrapHistory(SuccessPage)
const RoutedPDFPage = wrapHistory(PDFPage)
const RoutedLoginPage = wrapHistory(LoginPage)

export default function App() {
  return (

    <Router>
      <ScrollToTop/>
      <ToastContainer/>
      <Routes>
          <Route exact path="/customer/:customerId" element={<RoutedContractPage/>}/>
          <Route exact path="/customer" element={<RoutedCustomerPage/>}/>
          <Route path="/success/:hash" element={<RoutedSuccessPage/>}/>
          <Route path="/pdf/:documentId" element={<RoutedPDFPage/>}/>
          <Route path="/" element={<RoutedLoginPage/>}/>
      </Routes>
    </Router>
  );
}

function Home() {
  return <h2>Home</h2>;
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}