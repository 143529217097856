import React, { Component } from 'react';
import { Navigate } from "react-router"
import { Form, Button, Message, Container } from "semantic-ui-react"
import appStore from "store/appStore"

export default function LoginPage() {
  return (
    <div className={"LoginPage"}>
      <Container>
        <div className="pt6-ns pt4 mw6 center">
          <FormLogin/>
        </div>
      </Container>
    </div>
  );
}

class FormLogin extends React.Component {

  state = {
    username: "",
    password: "",
    loading : false,
  }


  handleSubmit = async () => {
    const { username, password } = this.state
    try {
      this.setState( { error: false, loading: true } )
      let session = await appStore.login( { username, password } )
      this.setState( { loading: false } )
      console.log( { session, username, password } )
      if ( session ) this.setState( { loading: false, success: true } )

    } catch ( err ) {
      console.log( "error", { err } )
      this.setState( { error: "Fehler bei der Anmeldung", loading: false } )
    }
  }

  render() {
    const { username, password, loading, success, error } = this.state
    if ( success ) return <Navigate to={"/customer"}/>
    return <Form onSubmit={this.handleSubmit}>
      <Form.Field>
        {error && <Message negative>{error}</Message>}
        <label>Vertreter (E-Mail)</label>
        <input autoComplete="username" value={username} placeholder='E-Mail' onChange={e => {
          this.setState( { username: e.target.value } )
        }}/>
      </Form.Field>
      <Form.Field>
        <label>Vertreter-Code</label>
        <input autoComplete="current-password" value={password} type={"text"} placeholder='Vertreter-Code' onChange={e => {
          this.setState( { password: e.target.value } )
        }}/>
      </Form.Field>
      <Form.Field>
      </Form.Field>
      <Button fluid type='submit' loading={loading} disabled={loading}>Anmelden</Button>
    </Form>
  }
}