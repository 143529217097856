import axios from "axios"
import {observable, action, makeAutoObservable} from "mobx"
import store from "store"

const BASE_URL = process.env.REACT_APP_API_URL || ""

console.log( "URL", BASE_URL )

class AppStore {

  session = store.get( "session" )

  successByKey = {}
  constructor(props) {
    // make session an observable
    makeAutoObservable(this)
    this.session = observable( this.session )
  }

  async fetch( url, options ) {
    url = `${BASE_URL}${url}`
    let request = Object.assign( {
      method     : "GET",
      credentials: "include",
    }, options )
    let response = await axios( url, request )
    console.log( { request, response }, response.status )
    return response.data
  }

  async logout() {
    store.remove( "session" )
    this.session = false
    this.fetch( "/api/logout" )
  }

  async login( { username, password } ) {
    let response = await this.fetch( `/api/user`, {
      method     : "POST",
      credentials: "include",
      data       : { userId: `${username}`, password: `${password}` }
    } )
    console.log( {response} )
    if ( response ) {
      this.session = response
      store.set( "session", this.session )
    }
    return response
  }
}

const appStore = new AppStore()
window.app = appStore
export default appStore